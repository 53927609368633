import React, { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import Spinner from "components/Spinner";
import PayslipsPaginatedTable from "features/payslips/PayslipsPaginatedTable";
import { PayslipListResponse } from "types/payroll-types";
import IconButton from "components/IconButton";
import { fetchAllPayslipsAPI, fetchPayslipsPageAPI } from "api/apiHelper";
import ErrorBox from "components/ErrorBox";
import { usePermissions } from "app/hooks";


const Payslips = () => {
  const [payslips, setPayslips] = useState<PayslipListResponse>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [pageLimit, setPageLimit] = useState<number>(20);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const permissions = usePermissions() || [];

  async function getPayslips() {
    try {
      const response: AxiosResponse<PayslipListResponse> = await fetchAllPayslipsAPI(pageLimit);
      console.log("Payslips : ", response.data);
      setPayslips(response.data);
      setPageNumber(1);
      setIsLoading(false);
    }
    catch (error: any) {
      console.log(error);
      setError(error.message);
      setIsLoading(false);
    }
  }

  const getPayslipPage = async (pageUrl:string, action:string) => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<PayslipListResponse> = await fetchPayslipsPageAPI(pageUrl);
      console.log("Payslips Page : ", response.data);
      setPayslips(response.data);
      if (action === "next"){
        setPageNumber(pageNumber+1);
      }
      else if (action === "previous"){
        setPageNumber(pageNumber-1)
      }
      setIsLoading(false);
    }
    catch (error: any) {
      console.log(error);
      setError(error.message);
      setIsLoading(false);
    }
  }
  
  async function getPayslipPageWithLimit(pageSize:number) {
    setIsLoading(true);
    try {
      const response: AxiosResponse<PayslipListResponse> = await fetchAllPayslipsAPI(pageSize);
      console.log("Payslips Page Size Res: ", response.data);
      setPageLimit(pageSize);
      setPayslips(response.data);
      setPageNumber(1);
      setIsLoading(false);
    }
    catch (error: any) {
      console.log(error);
      setError(error.message);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    
    getPayslips();
  }, []);
  return (
    <div className="container mt-5">
      {
        
          <div>
            <div className="flex md:flex-row md:justify-between items-center mb-4">
              <div className="flex flex-wrap justify-start">
                {permissions && permissions.includes('payroll.view_payslipbatch') &&
                  <IconButton url="/payslip/batch" name="Batches" icon="fas fa-tasks" extraStyles="mb-2 md:mb-0" />
                }
                {permissions && (permissions.includes('payroll.view_paysliplineitemtype') || permissions.includes('payroll.add_paysliplineitemtype')) &&
                  <IconButton url="/payslip/line-item-types" name="Item Types" icon="fas fa-tasks" extraStyles="mb-2 md:mb-0" />
                }
              </div>
              <div className="flex flex-wrap justify-end md:justify-end">
                {permissions && permissions.includes('payroll.add_payslipbatch') &&
                  <IconButton url="/payslip/batch/create" name="Create Batch" icon="fas fa-plus" extraStyles="mb-2 md:mb-0" />
                }
                {permissions && permissions.includes('payroll.add_payslip') &&
                  <IconButton url="/payslip/create" name="Create" icon="fas fa-plus" extraStyles="mb-2 md:mb-0" />
                }
              </div>
            </div>
            {isLoading ? (
                <Spinner show={isLoading} />        
              )
               : (
                <>
                  {error && <ErrorBox errorMessage={error} />}
                  {payslips && <PayslipsPaginatedTable tableData={payslips} tableName="Payslips" pageLimit={pageLimit} currentPageNumber={pageNumber} getPayslipPage={getPayslipPage} getPayslipPageWithLimit={getPayslipPageWithLimit} />}
                </>
              )
            }
          </div>
        
      }

    </div>
  );
};
export default Payslips;