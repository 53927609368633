import React from "react";
import { RiMoonFill, RiSunFill } from "react-icons/ri";

interface DarkModeToggleButtonPropsType {
  className?: string;
}

const DarkModeToggleButton = (props: DarkModeToggleButtonPropsType) => {
  const { className } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains("dark")
  );

  const toggleDarkMode = () => {
    if (darkmode) {
      document.body.classList.remove("dark");
      setDarkmode(false);
    } else {
      document.body.classList.add("dark");
      setDarkmode(true);
    }
  };

  return (
    <button
      className={`border-px flex items-center justify-center rounded-full border-[#6a53ff] bg p-0 text-green text-white ${className}`}
      onClick={toggleDarkMode}
    >
      {darkmode ? (
        <RiSunFill className="h-4 w-4" />
      ) : (
        <RiMoonFill className="h-4 w-4" />
      )}
    </button>
  );
}

export default DarkModeToggleButton;
