import BioDataForm from "features/employees/BioDataForm";

const BioData = () => {
  return (
    <div className="mt-5">
      <BioDataForm />
    </div>
  )
}

export default BioData;
