import React, { useState, useRef, useEffect } from "react";
import Button from "components/Button";

interface FileInputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  extra?: string;
  state?: "default" | "error" | "success";
  acceptedFileTypes: string[];
  onFileChange?: (file: File | null) => void;
  onCancel?: () => void;
  required?: boolean;
}

const FileInputField = (props: FileInputFieldProps) => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { label, id, extra, state, acceptedFileTypes, onFileChange, onCancel, required } = props;

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const selectedFile = files[0];
      handleFileChange(selectedFile);
    }
  };

  const handleFileChange = (selectedFile: File) => {
    if (acceptedFileTypes.includes(selectedFile.type)) {
      setFileName(selectedFile.name);
      setPreview(null);
      if (onFileChange) {
        onFileChange(selectedFile);
      }
    } else {
      console.error("Invalid file type");
    }
  };

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      handleFileChange(selectedFile);
    }
  };

  const handleCancel = () => {
    setFileName(null);
    setPreview(null);
    inputRef.current.value = "";
    if (onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  return (
    <div
      className={`${extra}`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {label && (
        <label
          htmlFor={id}
          className="font-bold text-navy-700 first-letter:text-sm dark:text-white"
        >
          {label}
        </label>
      )}

      <div
        className={`mt-2 flex h-36 w-full items-center justify-center rounded-xl border border-dashed border-gray-200 dark:border-white/10 ${
          state === "error"
            ? "border-red-500"
            : state === "success"
            ? "border-green-500"
            : ""
        }`}
      >
        {fileName ? (
          <div className="flex flex-col items-center justify-center p-3">
            <p className="truncate text-sm text-gray-500 dark:text-gray-400">
              {fileName}
            </p>
            <Button
              type="button"
              varient="secondary"
              className="-max rounded-lg !px-4 !py-2 text-xs"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center p-3 text-sm">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Drag and drop your file or click the upload button
            </p>
            {required && (
              <p className="text-red-500 text-sm">This field is required</p>
            )}
            <Button
              type="button"
              varient="primary"
              className="w-max rounded-lg !px-4 !py-2 text-xs"
              onClick={handleButtonClick}
            >
              Upload
            </Button>
          </div>
        )}
        <input
          ref={inputRef}
          type="file"
          id={id}
          accept={acceptedFileTypes.join(",")}
          hidden
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default FileInputField;
