import { useState, useEffect } from "react";
import CardMenu from "components/CardMenu";
import Card from "components/Card";
import { useNavigate, NavigateFunction } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ColumnDataType, PayslipLineItemType, BatchCreateType, BatchType } from "types/payroll-types";
import { payslipBatchCreateAPI } from "api/apiHelper";
import { toast } from "react-toastify";

type RowObj = EmployeeType;

interface EmployeePayslipItem {
  id: string;
  first_name: string;
  types: { id: string; amount: number }[];
}

interface CreateBatchPayslipTable2Type {
  columnsData: ColumnDataType[];
  tableData: EmployeeType[];
  ItemTypes: PayslipLineItemType[];
  batchStart: BatchType["period_start"];
  batchEnd: BatchType["period_end"];
  tableName: string;
}
const CreateBatchPayslipTable2 = (props: CreateBatchPayslipTable2Type) =>{
  const { tableData, tableName, ItemTypes, columnsData, batchStart, batchEnd } = props;
  const [employeesPayslipItems, setEmployeePayslipItems] = useState<EmployeePayslipItem[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const navigate: NavigateFunction = useNavigate();

  let defaultData = tableData;
  const columnHelper = createColumnHelper<RowObj>();
  const column = 
    columnHelper.accessor("first_name", {
      id: "first_name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          First Name
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    })
  
  const columns = [];
  columns.push(column);
  
  columnsData.map((column,index )=> columns.push({
    
    ...columnHelper.accessor("last_name", {
      id: `input${index}`,
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          {column.Header}
        </p>
      ),

    }),
  }));
  
  const [data, setData] = useState<RowObj[]>(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    const predefinedValues = tableData.map((employee: EmployeeType) => {
      const types = ItemTypes.map((type: PayslipLineItemType) => ({
        "id": type.id,
        "amount": 0
      }));
      return { "id": employee.id, "first_name": employee.first_name, types };
    });

    setEmployeePayslipItems(predefinedValues);
    console.log("PredefinedValues: ", predefinedValues);
  }, [tableData, ItemTypes]);

  const handleAmountChange = (employeeId: string, typeId: string, newAmount: number) => {
    setEmployeePayslipItems((prevItems: EmployeePayslipItem[]) =>
      prevItems.map((item: EmployeePayslipItem) =>
        item.id === employeeId
          ? {
            ...item,
            types: item.types.map((type: { id: string; amount: number }) =>
              type.id === typeId ? { ...type, amount: newAmount === 0 ? 0 : newAmount } : type
            ),
          }
          : item
      )
    );
  };

  async function generateBatchPayslips() {
    const batch_data: BatchCreateType = {
      period_start: batchStart,
      period_end: batchEnd,
      employees_items_amounts: employeesPayslipItems
    }
    const notification = toast.loading("Creating Payslip Batch...");
    try {
      const response = await payslipBatchCreateAPI(batch_data);
      console.log("Generate Payslips Response: ", response.data);
      toast.update(notification, {
        render: "Payslip Batch created successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      navigate(response.data?.id ? `/payslip/batch/${response.data.id}` : "/payslip/batch/")
    } catch (error) {
      toast.update(notification, {
        render: "Failed to create payslip batch.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      console.log(error);
    }
  };

  const handleGeneratePayslips = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("Payslips Generate Data: ", employeesPayslipItems);
    generateBatchPayslips();

  };

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <form onSubmit={handleGeneratePayslips}>
        <header className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {tableName}
          </div>
          <CardMenu />
        </header>

        <div className="mt-8 overflow-x-auto">
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="!border-px !border-gray-400">
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table
                .getRowModel()
                .rows
                .map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell, index) => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[150px] border-white/0 py-3  pr-4"
                          >
                            {cell.column.id === 'first_name' ?
                              flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              ) :
                              <input 
                                type="number"
                                step="0.01"
                                value={
                                  (employeesPayslipItems.length > 0 &&
                                  employeesPayslipItems.find((employee: EmployeePayslipItem) => employee?.id === row.original.id)?.types.find((type: {id: string, amount:number}) => type?.id === ItemTypes[index - 1]?.id)?.amount) || 0
                                }
                                onChange={(e) => {
                                  const newValue = parseFloat(e.target.value);
                                  handleAmountChange(row.original.id, ItemTypes[index - 1]?.id || '', isNaN(newValue) ? 0 : newValue);
                                }}
                                className="rounded border dark:bg-navy-700 bg-gray-100 px-3 py-2 w-full focus:border-blue-300 focus:outline-none focus:ring"
                              />
                            }
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="mt-8">
          
          <button
            type="submit"
            className="px-4 py-2 bg-brand-500 text-white rounded-md dark:bg-brand-400 dark:hover:bg-brand-500 hover:bg-brand-700"
          >
            Generate Payslips
          </button>
        </div>
      </form>
    </Card>
  );
}

export default CreateBatchPayslipTable2;
