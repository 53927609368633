import React from "react";

interface ButtonPropsType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  varient?: "primary" | "secondary" | "disabled" | "danger" | "success";
}

const Button = (props: ButtonPropsType) => {
  const { children, varient, className, ...rest } = props;

  let buttonClasses = 'linear mt-2 px-4 rounded-xl py-[12px] text-base font-medium';

  switch (varient) {
    case 'primary':
      buttonClasses += ' bg-brand-500 text-white hover:bg-brand-600 active:bg-brand-700 dark:hover:bg-brand-300 dark:active:bg-brand-200';
      break;
    case 'secondary':
      buttonClasses += ' bg-gray-100 hover:bg-gray-200 active:bg-gray-300 dark:bg-navy-600 dark:hover:bg-navy-500 dark:active:bg-navy-400';
      break;
    case 'disabled':
      buttonClasses += ' bg-gray-300 hover:bg-gray-300 active:bg-gray-400';
      break;
    case 'danger':
      buttonClasses += ' text-red-800 bg-red-200 hover:bg-red-300 active:bg-red-400 dark:hover:bg-red-500 dark:active:bg-red-400';
      break;
    case 'success':
      buttonClasses += ' text-green-800 bg-green-200 hover:bg-green-300 active:bg-green-400 dark:hover:bg-green-500 dark:active:bg-green-400'; 
      break;
    default:
      break; 
  }

  return (
    <button {...rest} className={buttonClasses + ' ' + className}>
      {children}
    </button>
  );
};

export default Button;
