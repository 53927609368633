import Card from "components/Card";
import { EmployeeLeaveInfoType } from "types/leaves-types";

interface LeavesInfoPropsType {
  leaveData: EmployeeLeaveInfoType[]; 
}

const LeavesInfo:React.FC<LeavesInfoPropsType> = ({ leaveData }) => {
  return (
    <Card extra={"w-full h-full pb-10 p-4"}>
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Employee Leaves Information
        </h4>
      </div>

      <div className="flex w-full flex-col gap-4">
        {leaveData?.map((leaveInfo: EmployeeLeaveInfoType, index: number) => (
          <div className="flex gap-4" key={index}>
            <div className="flex w-full p-4 flex-col justify-center rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">
                {leaveInfo.leave_type_description}s Allowed
              </p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {leaveInfo.max_leaves_allowed || 0}
              </p>
            </div>
            <div className="flex w-full p-4 flex-col justify-center rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">
                {leaveInfo.leave_type_description}s Taken
              </p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {leaveInfo.max_leaves_allowed - leaveInfo.remaining_leaves || 0}
              </p>
            </div>
						<div className="flex w-full p-4 flex-col justify-center rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">
                {leaveInfo.leave_type_description}s Remaining
              </p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {leaveInfo.remaining_leaves || 0}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default LeavesInfo;
