import { Link } from "react-router-dom";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { BatchType } from "types/payroll-types";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import Table from "components/Table";

interface BatchesDataTableType {
  tableData: BatchType[];
  tableName: string
}
const BatchesDataTable = (props: BatchesDataTableType) => {
  const { tableData, tableName } = props;

  const columnHelper = createColumnHelper<BatchType>();
  const columns:ColumnDef<BatchType, any>[]  = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <TableHeader>ID</TableHeader>
      ),
      cell: (info: any) => (
        <Link to={`/payslip/batch/${info.row.original.id}`} className="text-sm underline font-bold text-blue-700 dark:text-white">
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("month", {
      id: "month",
      header: () => (
        <TableHeader>Month</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {new Date(info.row.original.period_start).toLocaleString('default', { month: 'long' })}
        </TableCell>
      ),
    }),
    columnHelper.accessor("period_start", {
      id: "period_start",
      header: () => (
        <TableHeader>Period Start</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("period_end", {
      id: "period_end",
      header: () => (
        <TableHeader>Period End</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
  ];
  
  return (
    <Table<BatchType> tableData={tableData} columns={columns} tableHeading={tableName} />

  )
}

export default BatchesDataTable;
