import { fetchEmployeeOwnProfileAPI } from "api/apiHelper";
import { AppDispatch } from "app/store";
import { setEmployeeProfile, setProfileError } from "./reducer";


export const setEmployeeProfileDetails = () => async (dispatch : AppDispatch) => {
    try {
      let response =  await fetchEmployeeOwnProfileAPI();
      if (response?.data) {
        let profileInfo = {
          name: response.data?.first_name + " " + response.data?.last_name,
          photoUrl: response.data?.photo,
          loading: false,
        }
        dispatch(setEmployeeProfile(profileInfo));
      }
    } catch (error: any) {
      console.log(error)
      dispatch(setProfileError(error));
    }
  };
