import { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import Backdrop from "components/Backdrop";
import { fetchEmployeeOwnProfileAPI, getEmployeeLeavesDetailsByUser, getEmployeeLeavesInfoByUser } from "api/apiHelper";
import ErrorBox from "components/ErrorBox";
import Banner from "components/Banner";
import General from "components/General";
import IconButton from "components/IconButton";
import { EmployeeLeaveInfoType, EmployeeLeavesDetailsType } from "types/leaves-types";
import EmployeeDependentCard from "features/employees/EmployeeDependentsCard"
import { usePermissions } from "app/hooks";

const EmployeesProfile = () => {
  const [employee, setEmployee] = useState<EmployeeType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const permissions = usePermissions() || [];

  useEffect(() => {
    const getEmployeeDetails = async () => {
      try {
        const response: AxiosResponse<EmployeeType> = await fetchEmployeeOwnProfileAPI();
        console.log("Profile Details : ", response.data);
        setEmployee(response.data);
        setIsLoading(false);
      }
      catch (error: any) {
        console.log(error);
        setError(error.message);
        setIsLoading(false);
      }
    };
    
    getEmployeeDetails();
  }, []);
  
  return (
    <div className="mt-5">
      {
        isLoading ? (
          <Backdrop show={isLoading} />

        ) : (
          <div>
            {error && <ErrorBox errorMessage={error} />}

            {employee && 
              <div className="grid grid-cols-1 gap-3">
                <div className="flex justify-end">
                  <IconButton url="/edit-profile" name="Edit Profile" icon="fas fa-edit" extraStyles="mb-2 md:mb-0" />
                  {permissions && permissions.includes('contracts.view_my_contract') &&
                    employee.contract_id && <IconButton url="/my-contract" name="View Contract" icon="fas fa-file-contract" extraStyles="mb-2 md:mb-0" />
                  }
                </div>
                <Banner employee={employee} />
                <General employee={employee} />
              </div>
            }
            {permissions && permissions.includes('employees.view_my_employeedependants') && (
                <EmployeeDependentCard dependents={employee.dependents} />
            )}
          </div>
        )
      }
    </div>
  );
};
export default EmployeesProfile;