import Card from "components/Card";
import { formatDate } from "utils/utilityFunctions";
import { DependantsDataType } from "types/employee-types";

interface EmployeeDependentCardProps {
  dependents: DependantsDataType[];
}

const relationshipMapping: { [key: string]: string } = {
  "C": "Child",
  "S": "Spouse",
  "P": "Parent",
  "O": "Other",
};

const EmployeeDependentCard: React.FC<EmployeeDependentCardProps> = ({ dependents }) => {
  if (!dependents || dependents.length === 0) {
    return (
      <Card extra={"mb-6 p-4 mt-5"}>
        <p className="text-center text-gray-500 text-2xl">No dependent found.</p>
      </Card>
    );
  }
  
  console.log("Dependents Data:", dependents);

  return (
      <Card extra={"w-full mb-6 p-4 mt-4"}>
        <h2 className="text-xl font-bold text-navy-700 dark:text-white mb-8">
          Dependents Information
        </h2>
        {dependents.map((dependent, index) => (
          <div key={index} className="mb-6">
            <h5 className="font-bold text-navy-700 dark:text-white mb-4">
              Dependent {index + 1}
            </h5>
            <div className="grid grid-cols-2 gap-4 px-2">
              <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="text-sm text-gray-600">First Name</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {dependent.first_name}
                </p>
              </div>

              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="text-sm text-gray-600">Last Name</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {dependent.last_name}
                </p>
              </div>

              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="text-sm text-gray-600">Date of Birth</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {formatDate(dependent.date_of_birth)}
                </p>
              </div>

              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="text-sm text-gray-600">Gender</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {dependent.gender === 'M' ? 'Male' : dependent.gender === 'F' ? 'Female' : 'Others'}
                </p>
              </div>

              <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                <p className="text-sm text-gray-600">Relationship</p>
                <p className="text-base font-medium text-navy-700 dark:text-white">
                  {relationshipMapping[dependent.relationship] || "Unknown"}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Card>
  );
};

export default EmployeeDependentCard;
