import { useEffect } from 'react';
import { useAppSelector } from "app/hooks";
import { useNavigate } from 'react-router-dom';
import { getLocalStorageTokens } from 'utils/utilityFunctions';
import { setEmployeeProfileDetails } from 'app/slices/Employee/thunk';

const Home = (): JSX.Element | null => {
  const navigate = useNavigate();
  const { accessToken, empName, empLoading } = useAppSelector(state => ({
    accessToken: state.auth.accessToken,
    empName: state.emp.name,
    empLoading: state.emp.empLoading,
  }));

  useEffect(() => {    
    if (accessToken || getLocalStorageTokens()) {
      setEmployeeProfileDetails();

      if (!empLoading) {
        if (empName) {
          navigate('/profile');
        } else {
          navigate('/bio-data');
        }
      }
    } else {
      navigate('/auth/sign-in');
    }
  }, [accessToken, empName, empLoading, navigate]);

  return null;
};

export default Home;
