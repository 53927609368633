import { useState } from "react";
import Card from "components/Card";
import InputField from "components/InputField";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import { postLeaveType } from "api/apiHelper";
import { toast } from "react-toastify";

export interface LeaveInfoType {
  id?: number;
  description: string;
  is_paid: boolean;
}

const RegisterLeaveTypeForm = () => {
  const [leaveData, setLeaveData] = useState<LeaveInfoType>({
    description: "",
    is_paid: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setLeaveData({
      ...leaveData,
      [id]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const notification = toast.loading("Registering Leave Type...");

    try {
      const response = await postLeaveType(leaveData);
      console.log(response);
      toast.update(notification, {
        render: "Leave Type registered successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      handleReset();
    } catch (error) {
      toast.update(notification, {
        render: "Failed to register Leave Type",
         type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      console.log(error);
    }
  };

  const handleReset = () => {
    setLeaveData({ description: "", is_paid: false });
  };

  return (
    <Card extra={"w-full h-full pb-10 p-4"}>
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Register Leave Type
        </h4>
      </div>

      <form className="flex gap-2 w-full flex-col" onSubmit={handleSubmit} onReset={handleReset}>
        <InputField
          label="Leave Type"
          id="description"
          type="text"
          placeholder="Enter Leave Type here..."
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={leaveData.description}
          required
        />
        <div className="flex gap-2">
          <label
            htmlFor="isPaid"
            className="text-sm text-navy-700 dark:text-white font-bold"
          >
            Is Paid
          </label>
          <Checkbox
            id="is_paid"
            onChange={handleChange}
            checked={leaveData.is_paid}
          />
        </div>
        <div className="flex justify-end gap-2">
          <Button type="reset" varient="secondary" className="w-max">Cancel</Button>
          <Button type="submit" varient="primary" className="w-max">Submit</Button>
        </div>
      </form>
    </Card>
  );
};

export default RegisterLeaveTypeForm;
