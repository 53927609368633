import Card from "components/Card";
import { formatAmount } from "utils/utilityFunctions";

interface BannerPropsType {
    employee: EmployeeType;
}


const General = (props: BannerPropsType) => {

  return (
    <Card extra={"w-full h-full p-3 pb-12"}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          General Information
        </h4>
        
      </div>
      {/* Cards */}
      <div className="grid grid-cols-2 gap-4 px-2">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">CNIC</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.employee.CNIC}
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Address</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.employee.address}
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Total Wage</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {formatAmount(props.employee.total_income)}
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Phone</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.employee.phone}
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Date of Birth</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.employee.date_of_birth}
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Company</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.employee.company}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default General;
