import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import Backdrop from "components/Backdrop";
import { PayslipItemTypeType } from "types/payroll-types";
import { fetchPayslipLineItemTypesAPI, payslipLineItemTypeCreateAPI } from "api/apiHelper";
import PayslipLineItemTypesTable from "features/payslips/PayslipLineItemTypesTable";
import Card from "components/Card";
import InputField from "components/InputField";
import SelectField from "components/SelectField";
import Button from "components/Button";
import { toast } from "react-toastify";
import SwitchField from "components/SwitchField";
import ErrorBox from "components/ErrorBox";
import { usePermissions } from "app/hooks";

const PaySlipLineItemTypes = () => {
  const [payslipLineItemTypes, setPayslipLineItemTypes] = useState<PayslipItemTypeType[]>([]);
  const [newPayslipItemType, setNewPayslipItemType] = useState<PayslipItemTypeType>({
    id: null,
    description : "",
    fixed_amount: null,
    is_positive: null,
    of_item: null,
    order: null,
    percentage: null,
    source_auto: false,
    special: null,
    taxable: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [createFormError, setCreateFormError] = useState<string>("");
  const permissions = usePermissions() || [];

  async function getPayslipLineItemTypes() {
    if (permissions && permissions.includes('payroll.view_paysliplineitemtype')) {
      try {
        const response: AxiosResponse<PayslipItemTypeType[]> = await fetchPayslipLineItemTypesAPI();
        console.log("Payslips Line Item Types: ", response.data);
        setPayslipLineItemTypes(response.data);
        setIsLoading(false);
      }
      catch (error: any) {
        console.log(error);
        setError(error.message);
        setIsLoading(false);
      }
    }
  }

  const handleCreateTypeFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCreateFormError("");
    console.log("Form: ", newPayslipItemType);
    const notification = toast.loading("Creating Item Type...");
    try {
      const response = await payslipLineItemTypeCreateAPI(newPayslipItemType);
      console.log(response);
      getPayslipLineItemTypes();
      createFormReset();
      toast.update(notification, { render: "Type created successfully", type: "success", isLoading: false, autoClose: 5000, });
    } catch (error: any) {
      console.log(error);
      toast.update(notification, { render: "Failed to create Employee", type: "error", isLoading: false, autoClose: 5000, });
      setCreateFormError(error.message)
    }
  };
  const createFormReset = () => {
    setNewPayslipItemType({
      id: null,
      description : "",
      fixed_amount: null,
      is_positive: null,
      of_item: null,
      order: null,
      percentage: null,
      source_auto: false,
      special: null,
      taxable: false,
    });
  };
  const handleCreateFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setNewPayslipItemType({
      ...newPayslipItemType,
      [id]: id === "taxable" || id === "source_auto" ? (e.target as HTMLInputElement).checked : id !== "description" && value === ""? null: value,
    });
  };



  useEffect(() => {
    getPayslipLineItemTypes()
  }, []);

  return (
    <div className="mt-5">
      {
        isLoading ? (
          <Backdrop show={isLoading} />

        ) : (
          <div>
            {permissions && permissions.includes('payroll.add_paysliplineitemtype') &&
              <Card extra={"w-full h-full pb-10 p-4 mb-4"}>
                <div className="mb-8 mt-2 w-full">
                  <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Create Payslip Line Item Type
                  </h4>
                </div>
                {createFormError && <ErrorBox errorMessage={createFormError} />}
                <form
                  className="flex flex-col gap-4"
                  onSubmit={handleCreateTypeFormSubmit}
                >
                  <div className="grid w-full grid-cols-1 gap-4">
                    <InputField
                      id="description"
                      label="Description"
                      type="text"
                      placeholder="Enter description"
                      extra="w-full"
                      variant="outlined"
                      onChange={handleCreateFormChange}
                      value={newPayslipItemType.description}
                      required
                    />
                  </div>
                  <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 my-4">
                  
                    <SwitchField
                        id="taxable"
                        desc="Taxable"
                        label="Taxable"
                        placeholder="Taxable"
                        mt={"me-6"}
                        mb={""}
                        checked={newPayslipItemType.taxable}
                        onChange={handleCreateFormChange}
                      />
                    <SwitchField
                        id="source_auto"
                        desc="Source Auto"
                        label="Source Auto"
                        placeholder="Source Auto"
                        mt={"me-6"}
                        mb={""}
                        checked={newPayslipItemType.source_auto}
                        onChange={handleCreateFormChange}
                        
                      />
                  </div>
                  <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
                    <SelectField
                      id="is_positive"
                      label="Effect"
                      options={[
                        { label: "None", value: ""},
                        { label: "+", value: "+" },
                        { label: "-", value: "-" },
                      ]}
                      value={newPayslipItemType.is_positive || ""}
                      onChange={handleCreateFormChange}
                      
                      
                    />
                    <InputField
                      id="order"
                      label="Order"
                      type="number"
                      placeholder="Enter position"
                      min="0"
                      extra="w-full"
                      variant="outlined"
                      onChange={handleCreateFormChange}
                      value={newPayslipItemType.order|| ""}
                      required
                    />
                    <InputField
                      id="percentage"
                      label="Percentage"
                      type="number"
                      placeholder="Enter percentage"
                      min="0"
                      max="1"
                      step="0.01"
                      extra="w-full"
                      variant="outlined"
                      onChange={handleCreateFormChange}
                      value={newPayslipItemType.percentage|| ""}
                    />
                    <InputField
                      id="fixed_amount"
                      label="Fixed Amount"
                      type="number"
                      placeholder="Enter Fixed Amount"
                      step="0.01"
                      extra="w-full"
                      variant="outlined"
                      onChange={handleCreateFormChange}
                      value={newPayslipItemType.fixed_amount|| ""}
                    />
                    <SelectField
                      id="special"
                      label="Special"
                      options={[
                        { label: "None", value: ""},
                        { label: "INCOME TAX CALCULATED", value: "INCOME TAX CALCULATED" },
                        { label: "INCOME TAX DEDUCTION", value: "INCOME TAX DEDUCTION" },
                        { label: "INCOME TAX NET", value: "INCOME TAX NET" },
                        { label: "NET SALARY", value: "NET SALARY" },
                      ]}
                      value={newPayslipItemType.special || ""}
                      onChange={handleCreateFormChange}
                      
                    />
                    <SelectField
                      id="of_item"
                      label="Of Item"
                      
                      options={[
                        { label: "None", value: "" },
                        ...payslipLineItemTypes.map((itemType) => ({ label: itemType.description, value: itemType.id })),
                      ]}
                      value={newPayslipItemType.of_item || ""}
                      onChange={handleCreateFormChange}
                      
                    />
                    </div>
                    <div className="flex justify-end gap-2">
                      <Button type="submit" varient="primary" className="w-max">
                        Create
                      </Button>
                    </div>

                </form>
              </Card>
            }

            {error && <ErrorBox errorMessage={error} />}
            {permissions && permissions.includes('payroll.view_paysliplineitemtype') &&
              <PayslipLineItemTypesTable tableData={payslipLineItemTypes} />
            }
          </div>

        )
      }

    </div>
  )
}

export default PaySlipLineItemTypes;
