import { Link, useLocation } from "react-router-dom";
import { filterRoutesByPermissions } from 'utils/utilityFunctions'
import { usePermissions } from "app/hooks";

import { MdDashboard } from "react-icons/md";

export function SidebarLinks(props:any) {
  let location = useLocation();
  const permissions = usePermissions() || [];
  const { routes } = props;
  const routesByPermissions = filterRoutesByPermissions(routes, permissions);

  const activeRoute = (routeName:any) => {
    if (routeName === "/") {
      return location.pathname === routeName;
    } else {
      return location.pathname.includes(routeName);
    }
  };

  const createLinks = (routesByPermissions:any) => {
    return routesByPermissions.map((route:any, index:any) => {
      if (
        route.display === true
      ) {
        return (
          <Link key={index} to={route.path}>
            <div className="relative mb-3 flex hover:cursor-pointer">
              <li
                className="my-[3px] flex cursor-pointer items-center px-8"
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-brand-500 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.icon ? route.icon : <MdDashboard />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path) === true
                      ? "font-bold text-navy-700 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path) ? (
                <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
              ) : null}
            </div>
          </Link>
        );
      }
    });
  };

  return createLinks(routesByPermissions);
}

export default SidebarLinks;
