import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import Table from "components/Table";
import { LeaveDetailsType } from "pages/leaves/Leaves"
import { Link } from "react-router-dom";
import { MdVisibility } from 'react-icons/md';

interface PendingLeavesTablePropsType {
  tableData: LeaveDetailsType[] | [];
  tableHeading: string;
}

const PendingLeavesTable = (props: PendingLeavesTablePropsType) => {
  const { tableData, tableHeading } = props;

  const columnHelper = createColumnHelper<LeaveDetailsType>();
  const columns: ColumnDef<LeaveDetailsType, any>[] = [
    columnHelper.accessor("employee_id", {
      id: "employee_id",
      header: () => (
        <TableHeader>Employee ID</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("employee_first_name", {
      id: "employee_first_name",
      header: () => (
        <TableHeader>First Name</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("employee_last_name", {
      id: "employee_last_name",
      header: () => (
        <TableHeader>Last Name</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("leave_type_description", {
      id: "eave_type_description",
      header: () => (
        <TableHeader>TYPE</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("leave_status", {
      id: "leave_status",
      header: () => (
        <TableHeader>STATUS</TableHeader>
      ),
      cell: (info) => (
        info.getValue() === "P" ? (
          <TableCell>
            <span className="bg-gray-200 !text-gray-700 px-2 py-1 w-max rounded-xl dark:bg-gray-700 dark:!text-gray-200">
              Pending
            </span>
          </TableCell> 
        ) : (
          <TableCell>
            Error
          </TableCell>
        )
      ),
    }),
    columnHelper.accessor("id", {
      id: "view",
      header: () => (
        <TableHeader>View</TableHeader>
      ),
      cell: (info) => (
        <Link to={`/leaves/${info.getValue()}`}>
          <TableCell className="!min-w-0 !pr-0 !w-max">
            <MdVisibility className="h-6 w-6 text-gray-600" />
          </TableCell>
        </Link>
      ),
    }),
  ];

  return (
    <Table<LeaveDetailsType> tableData={tableData}  columns={columns} tableHeading={tableHeading} />
  );
};

export default PendingLeavesTable;
