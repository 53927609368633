import { createContext, useState, ReactNode } from "react";

export type AuthType = string;

export interface AuthContextType {
  auth: AuthType;
  setAuth: React.Dispatch<React.SetStateAction<AuthType>>;
  permissions: UserPermissionType;
  setPermissions: React.Dispatch<React.SetStateAction<UserPermissionType>>;
}


const AuthContext = createContext<AuthContextType>({
  auth: "",
  setAuth: () => {},
  permissions: "",
  setPermissions: () => {}
});
// Using UseContext hook to provide auth and role state of user
export const AuthProvider = ({
  children
}: { children: ReactNode }) => {
  // ts-migration -> add interfaces for auth role and create context
  const [auth, setAuth] = useState<AuthType>("");
  const [permissions, setPermissions] = useState<UserPermissionType>("");

  return <AuthContext.Provider value={{ auth, setAuth, permissions, setPermissions }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
