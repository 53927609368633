export function setLocalStorageTokens(tokens: any) {
  localStorage.setItem("access_token", tokens);
  }

export function setLocalStoragePermissions(permissions: any) {
  const permissionsString = JSON.stringify(permissions);
  localStorage.setItem("permissions", permissionsString);
}

export function getLocalStorageTokens() {
  const localTokens = localStorage.getItem("access_token");
  if (localTokens) {
    return localTokens;
  }
  return "";
}

export function getLocalStoragePermissions(): UserPermissionType[] {
  try {
    const localPermissions = localStorage.getItem("permissions");
    if (localPermissions) {
      console.log("parse perms: ",JSON.parse(localPermissions));
      
      return JSON.parse(localPermissions);
    }
    return [];
  } catch(e) {
    return [];
  }
}

export function authHeader() {
  const accessToken= getLocalStorageTokens();
  const currentUrl = window.location.pathname;
  if (accessToken && !currentUrl.includes('/auth/')) {
    return { Authorization: `Token ${accessToken}` };   
  } else {
    return {};
  }
}

export function filterRoutesByPermissions(routes: RoutesType[], userPermissions:UserPermissionType[]) {
  if(!userPermissions || userPermissions.length < 1)
  {
    return routes;
  }

  return routes.filter((route) => {
    if (!route.permissions) {
      return true;
    }
    return userPermissions.some((perm) => route.permissions.includes(perm));
  });
}

export function logout() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("permissions");
  window.location.href = '/auth/sign-in';
}

export function formatAmount(num: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PKR',
    minimumFractionDigits: 0,
  });

  return formatter.format(num);
}

export function formatNumber(num: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
  });
  return formatter.format(num);
}



export const formatDate = (dateString: string): string => {
  if (!dateString) return '';

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
};
