import React, { useEffect } from "react";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import Footer from "components/Footer";
import { Outlet } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import { setEmployeeProfileDetails } from "app/slices/Employee/thunk";

export default function Admin() {
  const [open, setOpen] = React.useState(true);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  useEffect(() => {
    const currentUrl = window.location.pathname;
    if (!currentUrl.includes('/bio-data')) {
      dispatch(setEmployeeProfileDetails());
    }
  }, [dispatch]);
  document.documentElement.dir = "ltr";

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main className="mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]">
          {/* Routes */}
          <div className="h-full">
            <Navbar brandText="a" onOpenSidenav={() => setOpen(!open)} />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Outlet />
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
