import { useEffect, useState } from "react";
import { ClauseType } from "features/contracts/CreateContract";
import { AxiosResponse } from "axios";
import { getMyContractAPI } from "api/apiHelper";
import Backdrop from "components/Backdrop";
import Card from "components/Card";

export interface ContractType {
  id: string;
  clauses_in_contract: ClauseType[];
  employee: EmployeeType["id"];
}

const MyContract = () => {
  const [contract, setContract] = useState<ContractType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getMyContract = async () => {
    try {
      const response: AxiosResponse<ContractType> = await getMyContractAPI();
      console.log("Contract Data: ", response.data);
      setContract(response.data || null);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMyContract();
  }, []);

  return (
    <div className="mt-5">
      {isLoading ? (
        <Backdrop show={isLoading} />
      ) : (
        <div>
          {contract ? (
            <Card>
              <div className="p-5">
                <h4 className="mb-3 p-2 text-center text-2xl font-bold text-navy-700 dark:text-white">
                  Contract Clauses
                </h4>
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="border-b border-gray-200 text-navy-700 dark:text-white">
                    <tr>
                      <th className="px-6 py-3 text-left text-base font-bold ">
                        No.
                      </th>
                      <th className="px-6 py-3 text-left text-base font-bold ">
                        Legal Text
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {contract.clauses_in_contract.map((item, index) => (
                      <tr key={index} className="">
                        <td className="px-6 py-4 ">{index + 1}</td>
                        <td className="px-6 py-4 text-gray-600">
                          {item.legal_text}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          ) : (
            <div className="flex mt-32 items-center justify-center">
              <p className="text-center text-3xl font-bold text-gray-500">
                No Contract Found
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyContract;
