import { authCodeType, loginAPI, loginGoogleAPI, loginUserType } from "api/apiHelper";
import { AppDispatch } from "app/store";
import { loginFailure, loginSuccess, setAuthLoading } from "./reducer";
import { setLocalStoragePermissions, setLocalStorageTokens } from "utils/utilityFunctions";


export enum LoginType {
  Default = "default",
  Google = "google",
}

export const loginUser = (
    data:loginUserType | authCodeType, 
    onLoginSuccess: ()=>void, 
    loginType:LoginType=LoginType.Default 
  ) => async (dispatch : AppDispatch) => {
    try {

      dispatch(setAuthLoading(true));

      let response;

      if(loginType === LoginType.Google){
        response =  await loginGoogleAPI(data as authCodeType);
      }
      else {
        response =  await loginAPI(data as loginUserType);
      }
      dispatch(loginSuccess(response.data))
      setLocalStorageTokens(response.data.token);
      setLocalStoragePermissions(response.data.permissions);
      dispatch(setAuthLoading(false));
      onLoginSuccess()
    } catch (error: any) {
      console.log(error)
      dispatch(loginFailure(error.message));
      dispatch(setAuthLoading(false));
    }
  };

