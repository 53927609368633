import { BreadCrumbDataType } from 'components/BreadCrumbs';
import { useLocation, useParams } from 'react-router-dom';
import { userRoutes } from 'routes';



const useBrandText = () => {
  const location = useLocation();  
  const params = useParams();
  const paramKeys = Object.keys(params);
  let pathWithIdPlaceholder = location.pathname;

  paramKeys.forEach(key => {
    pathWithIdPlaceholder = pathWithIdPlaceholder.replace(params[key], `:${key}`);
  });
  const homeBreadCrumb: BreadCrumbDataType = {
    routePath: '/',
    routeLink: '/',
    routeName: 'Home',
  }
  const routeSegments = pathWithIdPlaceholder.split('/').filter(segment => segment !== '');
  const locationSegments:string[] = location.pathname.split('/').filter(segment => segment !== '');

  const breadcrumbs:BreadCrumbDataType[] = locationSegments.map((segment, index) => {
    const routePath = routeSegments.slice(0, index + 1).join('/');
    const routeLink = locationSegments.slice(0, index + 1).join('/');
    const routeName =  segment;
    return {
      routePath: `/${routePath}`,
      routeLink: `/${routeLink}`,
      routeName: routeName,
    };
  });

  const currentRoute = userRoutes.find(route => route.path === pathWithIdPlaceholder);

  return { 
    name: currentRoute ? currentRoute.name : 'Main',
    id: params[paramKeys[paramKeys.length-1]] || '',
    bread_crumbs: [homeBreadCrumb, ...breadcrumbs],
  };
};

export default useBrandText;
