import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import { Link } from "react-router-dom";
import Table from "components/Table";
import { MdVisibility } from 'react-icons/md';
import { FormType } from "pages/appraisals/Appraisals";

interface AllFormsTablePropsType {
  title: string;
  tableData: FormType[] | [];
 }

const AllFormsTable = (props: AllFormsTablePropsType) => {
  const { title, tableData } = props;

  const columnHelper = createColumnHelper<FormType>(); 
  const columns: ColumnDef<FormType, any>[] = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <TableHeader>ID</TableHeader> 
      ),
      cell: (info) => (
       <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("title", {
      id: "title",
      header: () => (
        <TableHeader>Title</TableHeader> 
      ),
      cell: (info) => (
       <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => (
        <TableHeader>Description</TableHeader> 
      ),
      cell: (info) => (
       <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("id", {
      id: "view",
      header: () => (
        <TableHeader>View</TableHeader>
      ),
      cell: (info) => (
        <Link to={`form/${info.getValue()}`}> 
          <TableCell className="!min-w-0 !pr-0 !w-max">
            <MdVisibility className="h-6 w-6 text-gray-600" />
          </TableCell>
        </Link>
      ),
    }),
  ];

  return (
    <Table<FormType> tableData={tableData} columns={columns} tableHeading={title} /> 
  );
}

export default AllFormsTable;
