import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Card from "components/Card";
import InputField from "components/InputField";
import FileUploadField from "components/FileInputField";
import SelectField from "components/SelectField";
import Button from "components/Button";
import { toast } from "react-toastify";
import { fetchSpecificEmployeeAPI } from "api/apiHelper";
import { updateEmployeeAPI } from "api/apiHelper";
import { useNavigate } from "react-router-dom";
import Backdrop from "components/Backdrop";
import ErrorBox from "components/ErrorBox";
import { DependantsDataType } from "types/employee-types";
import { usePermissions } from "app/hooks";

export interface EmployeeUpdateType {
  employee: EmployeeType;
  dependents: DependantsDataType[];
}


const EmployeeBioDataForm = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const permissions = usePermissions() || [];
  const [employeeDetails, setEmployeeDetails] = useState<EmployeeType | null>(null);
  const fetchEmployeeDetails = async (id: string) => {
    setError("");
    if(permissions && permissions.includes('employees.view_employee') ) {
      try {
        const response = await fetchSpecificEmployeeAPI(id);
        const photo = await fetch(response.data.photo);
        const photoBlob = await photo.blob();
        const photoFile = new File([photoBlob], "profile.jpg", { type: "image/jpeg" });
        response.data.photo = photoFile;
        setEmployeeDetails(response.data)
        setIsLoading(false);
        console.log(response.data, "Employee Details")
      } catch (error: any) {
        console.log(error);
        setIsLoading(false);
        setError(error.message);
      }
    }
    else {
      setIsLoading(false);
    }
  }

  const [formData, setFormData] = useState<EmployeeUpdateType>({
    employee: {
      id: "",
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
      CNIC: "",
      phone: "",
      address: "",
      photo: "",
      total_income: 0,
      designation: "",
      company: "",
      user: null,
      date_joined: null,
    },
    dependents: [],
  });

  useEffect(() => {
    if(!employeeId.match(/\d+/)) {
      navigate('/not-found');
      return;
    }
    fetchEmployeeDetails(employeeId)
  }, [employeeId])

  const initializeFormData = (employeeDetails: EmployeeType | null) => {
    if (employeeDetails) {
      setFormData({
        employee: {
          id: employeeDetails.id || "",
          first_name: employeeDetails.first_name || "",
          last_name: employeeDetails.last_name || "",
          date_of_birth: employeeDetails.date_of_birth || "",
          gender: employeeDetails.gender || "",
          CNIC: employeeDetails.CNIC || "",
          phone: employeeDetails.phone || "",
          address: employeeDetails.address || "",
          photo: employeeDetails.photo || "",
          total_income: employeeDetails.total_income || 0,
          designation: employeeDetails.designation || "",
          company: employeeDetails.company || "",
          user: employeeDetails.user,
          date_joined: employeeDetails.date_joined,
        },
        dependents: employeeDetails.dependents,
      });
    }
  }

  useEffect(() => {
    initializeFormData(employeeDetails);
  }, [employeeDetails])


  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form Data: ", formData)
    const notification = toast.loading("Updating Employee Info...");
    if (!formData.employee.photo)
    {  
      toast.update(notification, {
        render: "Photo is required field.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      return;
    }
    try {
      const response = await updateEmployeeAPI(employeeId, formData);
      console.log(response);
      toast.update(notification, {
        render: "Employee updated successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      navigate(`/employee/${employeeId}`);   
    } catch (error) {
      toast.update(notification, {
        render: "Failed to update Employee",
         type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      console.log(error);
    }

  };

  const handleRemoveDependent = (index: number) => {
    const newDependents = formData.dependents.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      dependents: newDependents,
    });
  };
  

  const handleFormReset = () => {
    initializeFormData(employeeDetails);
  };

  const handleFileChange = (file: File | null) => {
    if (file) {
      setFormData({
        ...formData,
        employee: {
          ...formData.employee,
          photo: file,
        },
      });
    }
  };

  const handleFileCancel = () => {
    setFormData({
      ...formData,
      employee: {
        ...formData.employee,
        photo: null,
      },
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    console.log("Form Data Ch: ",formData);
    const { id, value } = e.target;
    setFormData({
      ...formData,
      employee: {
        ...formData.employee,
        [id]: value? value: id==='total_income'? 0 : "",
      },
    });
  };

  const  handleDependentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { id, value } = e.target;
    const newDependents = formData.dependents.map((dependent, i) => {
      if (i === index) {
        return {
          ...dependent,
          [id]: value,
        };
      }
      return dependent;
    });

    setFormData({
      ...formData,
      dependents: newDependents,
    });
  };

  const handleAddDependent = () => {
    const newDependent: DependantsDataType = {
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "M",
      relationship: "Spouse",
    };

    setFormData({
      ...formData,
      dependents: [...formData.dependents, newDependent],
    });
  };


  return (
    isLoading ? (
      <Backdrop show={isLoading} />

    ) : (
    permissions && permissions.includes('employees.view_employee') ? (
    <Card extra={"w-full h-full pb-10 p-4"}>
      {/* Header */}
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Bio Data Form
        </h4>
      </div>
      { error && <ErrorBox errorMessage={error} />}
      {/* Form */}
      <form
        className="flex flex-col gap-4"
        onSubmit={handleFormSubmit}
        onReset={handleFormReset}
      >
        <FileUploadField
          id="photo"
          label="Profile photo"
          acceptedFileTypes={[
            "image/jpeg",
            "image/png",
            "image/jpg",
            "image/svg",
          ]}
          onFileChange={handleFileChange}
          onCancel={handleFileCancel}
          value={formData.employee.photo}
          required={!formData.employee.photo}
        />
        <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
          <InputField
            id="first_name"
            label="First Name"
            type="text"
            placeholder="Enter first name"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.first_name}
            required
          />
          <InputField
            id="last_name"
            label="Last Name"
            type="text"
            placeholder="Enter last name"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.last_name}
            required
          />
          <InputField
            id="company"
            label="Company"
            type="text"
            placeholder="Enter company name"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.company}
            required
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
          <InputField
            id="total_income"
            label="Total Income"
            type="number"
            placeholder="Enter total income"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.total_income}
            required
          />
          <InputField
            id="phone"
            label="Phone"
            type="text"
            placeholder="Enter phone"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.phone}
            required
          />
          <InputField
            id="designation"
            label="Designation"
            type="text"
            placeholder="Enter designation"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.designation}
            required
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
          <InputField
            id="date_of_birth"
            label="Date of Birth"
            type="date"
            placeholder="Enter date of birth"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.date_of_birth}
            required
          />
          <SelectField
            id="gender"
            label="Choose a gender"
            options={[
              { label: "Select", value: ""},
              { label: "Male", value: "M" },
              { label: "Female", value: "F" },
              { label: "Other", value: "O" },
            ]}
            onChange={handleChange}
            value={formData.employee.gender}
            required
          />
          <InputField
            id="CNIC"
            label="CNIC"
            type="text"
            placeholder="Enter CNIC"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.CNIC}
            required
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-4">
          <InputField
              id="address"
              label="Address"
              type="text"
              placeholder="Enter address"
              extra="w-full"
              variant="outlined"
              onChange={handleChange}
              value={formData.employee.address}
              required
            />
        </div>

        <div className="mb-8 mt-2 flex w-full items-center justify-between border-t pt-8">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Dependants Details
          </h4>
          <Button
            type="submit"
            varient="primary"
            className="rounded px-4 py-2"
            onClick={handleAddDependent}
          >
            Add Dependent
          </Button>
        </div>

        {formData.dependents.map((dependent, index) => (
          <div className="mb-8 mt-2 w-full flex flex-col gap-4" key={index}>
            <div className="flex justify-between items-center">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              Dependant {index + 1}
            </h4>
            <Button
              type="button"
              varient="danger"
              className="w-max mt-4"
              onClick={() => handleRemoveDependent(index)}
            >
              Remove Dependent
            </Button>
          </div>
            <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-2">
              <InputField
                id="first_name"
                label="First Name"
                type="text"
                placeholder="Enter first name"
                extra="w-full"
                variant="outlined"
                onChange={(e) => handleDependentChange(e, index)}
                value={formData.dependents[index].first_name}
                required
              />
              <InputField
                id="last_name"
                label="Last Name"
                type="text"
                placeholder="Enter last name"
                extra="w-full"
                variant="outlined"
                onChange={(e) => handleDependentChange(e, index)}
                value={formData.dependents[index].last_name}
                required
              />
            </div>
            <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
              <InputField
                id="date_of_birth"
                label="Date of Birth"
                type="date"
                placeholder="Enter date of birth"
                extra="w-full"
                variant="outlined"
                onChange={(e) => handleDependentChange(e, index)}
                value={formData.dependents[index].date_of_birth}
                required
              />
              <SelectField
                id="gender"
                label="Choose a gender"
                options={[
                  { label: "Select", value: ""},
                  { label: "Male", value: "M" },
                  { label: "Female", value: "F" },
                  { label: "Other", value: "O" },
                ]}
                onChange={(e) => handleDependentChange(e, index)}
                defaultValue={formData.dependents[index].gender}
                required
              />
              <SelectField
                id="relationship"
                label="Relationship"
                options={[
                  { label: "Select", value: ""},
                  { label: "Spouse", value: "S" },
                  { label: "Child", value: "C" },
                  { label: "Parent", value: "P" },
                  { label: "Other", value: "O" },
                ]}
                onChange={(e) => handleDependentChange(e, index)}
                defaultValue={formData.dependents[index].relationship}
                required
              />
            </div>
          </div>
        ))}
        <div className="flex justify-end gap-2">
          <Button type="reset" varient="secondary" className="w-max">
            Cancel
          </Button>
          <Button type="submit" varient="primary" className="w-max">
            Submit
          </Button>
        </div>
      </form>
    </Card>
    ): (
      <ErrorBox errorMessage="Insufficient priviliges." />
    )
    )
  );
};

export default EmployeeBioDataForm;
