import React from "react";
import { Link } from "react-router-dom";

interface ButtonPropsType {
  url: string;
  name: string;
  icon: string;
  extraStyles?: string;
}

const IconButton = (props: ButtonPropsType) => {
  return (
    <Link to={props.url}>
      <button className={`bg-brand-500 mx-2 flex justify-between items-center uppercase hover:bg-brand-600 dark:bg-brand-400 dark:hover:bg-brand-500 rounded-lg py-2 px-3 text-[0.8rem] font-bold text-white ${props.extraStyles}`}>
        {props.name}
        <div className="flex h-5 w-5 items-center justify-center ml-1">
          <i className={props.icon} aria-hidden="true"></i>
        </div>
      </button>
    </Link>

  )
};

export default IconButton;