import React from "react";

interface SelectFieldProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  extra?: string;
  options: { value: string; label: string }[]; 
  state?: 'error' | 'success';
}

const SelectField: React.FC<SelectFieldProps> = (props) => {
  const { label, extra, options, state, ...rest } = props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={props.id}
        className="text-sm text-navy-700 dark:text-white font-bold"
      >
        {label}
      </label>
      <select
        {...rest}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:bg-navy-800 ${
          props.disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5"
            : state === "error"
            ? "border-red-500 text-red-500 dark:!border-red-400 dark:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 dark:!border-green-400 dark:!text-green-400"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
