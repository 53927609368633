import React from "react";
import Dropdown from "components/Dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import avatar from "assets/img/avatars/avatar4.png";
import useBrandText from "../hooks/useBrandText";
import { logout } from "utils/utilityFunctions";
import DarkModeToggleButton from "components/DarkModeToggleButton";
import BreadCrumbs from "components/BreadCrumbs";
import { useAppSelector } from "app/hooks";
import { FaUserCircle } from "react-icons/fa";

const Navbar = (props:any) => {
  const { onOpenSidenav } = props;
  const { name, id, bread_crumbs } = useBrandText();
  const { empName, empPhoto } = useAppSelector(state => ({
    empName: state.emp.name,
    empPhoto: state.emp.photoUrl
  }));

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="pt-1 mb-3">
          <BreadCrumbs bread_crumbs={bread_crumbs} />
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {name} { id ? `# ${id}` : "" }
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1 xl:gap-2">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        <DarkModeToggleButton className="!text-gray-600 dark:!text-white mx-4" />
        <Dropdown
          button={
            empPhoto?
              <img
                className="h-10 w-10 rounded-full"
                src={empPhoto}
                alt="Elon Musk"
              />
              : <FaUserCircle className="h-10 w-10" />
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {empName? empName: "User"}
                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-4">
                <button
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                  onClick={() => {
                    logout();
                    window.location.reload();
                  }}
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;