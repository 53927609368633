import EditEmployeeBioDataForm from "features/employees/EditEmployeeBioDataForm";

const EditEmployeeBioData = () => {
  return (
    <div className="mt-5">
      <EditEmployeeBioDataForm />
    </div>
  );
}

export default EditEmployeeBioData;
