import React, { useEffect } from "react";
import Card from "components/Card";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  useReactTable,
  ColumnDef
} from "@tanstack/react-table";

interface TablePropsType<TableDataType> {
  tableData: TableDataType[];
  columns: ColumnDef<TableDataType>[];
  tableHeading: string;
  children?: React.ReactNode;
}

const Table = <TableDataType,>(props: TablePropsType<TableDataType>) => {
  const { tableData, columns, tableHeading, children } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [data, setData] = React.useState(() => [...tableData]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0, //initial page index
    pageSize: 20, //default page size
  });

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    debugTable: true,
  });

  return (
    <Card extra={"w-full pb-7 p-4 h-full"}>
      <header className="relative flex items-top justify-between h-20">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          {tableHeading}
        </div>
        <div>
          {children}
        </div>
      </header>
      <div className="overflow-x-auto px-1">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: "",
                        desc: "",
                      }[header.column.getIsSorted() as string] ?? null}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length > 0 ? ( 
              table
                .getRowModel()
                .rows.slice(0, tableData.length)
                .map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan={columns.length} className="text-center pt-8 text-sm font-semibold text-gray-400 dark:text-white min-w-[150px] border-white/0 py-3 pr-4">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex justify-between items-center mb-2 mt-5">
          <div className="flex items-center">
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className={`px-4 py-2 mr-2 border rounded-md ${
                !table.getCanPreviousPage() ? 'bg-gray-200 dark:bg-gray-800 cursor-not-allowed' : 'bg-brand-500 text-white dark:bg-brand-400 hover:bg-brand-700'
              }`}
            >
              {'<'}
            </button>
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className={`px-4 py-2 border rounded-md ${
                !table.getCanNextPage() ? 'bg-gray-200 cursor-not-allowed' : 'bg-brand-500 text-white dark:bg-brand-400 hover:bg-brand-700'
              }`}
            >
              {'>'}
            </button>
            <span className="text-base text-gray-600 ml-3">
              Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </span>
          </div>
          <div className="text-center">
            <span className="text-base text-gray-600">
              Showing {table.getRowModel().rows.length} of {table.getCoreRowModel().rows.length} results
            </span>
          </div>
          <div>
            <select
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
              className="border bg-white dark:bg-brand-400 py-2 ps-4 pe-6 rounded-md"
            >
              {[5, 10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Table;
