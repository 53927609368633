import { createSlice } from "@reduxjs/toolkit";

interface EmployeeStateType {
  name: string;
  photoUrl: string;
  profileError: any;
  empLoading: boolean;
}
export const initialState: EmployeeStateType = {
  name: "",
  photoUrl: "",
  profileError: null,
  empLoading: true,
};

const employeeSlice = createSlice({
  name: "emp",
  initialState,
  reducers: {
    setEmployeeProfile(state, action) {
      state.name = action.payload.name;
      state.photoUrl = action.payload.photoUrl;
      state.profileError = initialState.profileError;
      state.empLoading = action.payload.loading;
    },
    setProfileError(state,action) {
      state.profileError = action.payload;
    }
  }
});

export const { setEmployeeProfile, setProfileError } = employeeSlice.actions;

export default employeeSlice.reducer;
