import { useState, useEffect } from "react";
import InputField from "components/InputField";
import SelectField from "components/SelectField";
import Card from "components/Card";
import { getRemainingLeavesTypesInfo } from "api/apiHelper";
import { EmployeeLeaveInfoType } from "types/leaves-types";
import { postRequestLeave } from "api/apiHelper";
import Button from "components/Button";
import { toast } from "react-toastify";

export interface RequestLeaveType {
  leave_info_id: string;
  start_date: string;
  end_date: string;
  reason: string;
}

export interface LeaveOptionsType {
  value: string;
  label: string;
}

const RequestLeaveForm = () => {
  const [remainingLeaves, setRemainingLeaves] = useState<EmployeeLeaveInfoType[]>([]);
  const [leaveOptions, setLeaveOptions] = useState<LeaveOptionsType[]>([]);
  const [formData, setFormData] = useState<RequestLeaveType>({
    leave_info_id: "",
    start_date: "",
    end_date: "",
    reason: ""
  });

  const fetchLeaves = async () => {
    try {
      const response = await getRemainingLeavesTypesInfo();
      setRemainingLeaves(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchLeaves();
  }, []);

  useEffect(() => {
    const options = remainingLeaves.map(leave => ({
      value: leave.id.toString(),
      label: leave.leave_type_description,
    }));
    options.unshift({ value: "", label: "Select an option" });
    setLeaveOptions(options);
  }, [remainingLeaves]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const notification = toast.loading("Requesting Leave...");

    try {
      const response = await postRequestLeave(formData);
      console.log("Request Leave Response: ", response);
      toast.update(notification, {
        render: "Leave Requested successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      handleReset();
    } catch (error) {
      console.log(error);
      toast.update(notification, {
        render: "Failed to request Leave",
         type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const handleReset = () => {
    setFormData({
      leave_info_id: "",
      start_date: "",
      end_date: "",
      reason: ""
    });
  };

  return (
    <Card extra={"w-full h-full pb-10 p-4"}>
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Request Leave
        </h4>
      </div>

      <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit} onReset={handleReset}>
        <SelectField 
          id="leave_info_id"
          label="Choose an Option"
          options={leaveOptions}
          onChange={handleChange}
        />
        <InputField
          id="start_date"
          label="Start Date"
          type="date"
          placeholder="Enter start date"
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={formData.start_date}
        />
        <InputField
          id="end_date"
          label="End Date"
          type="date"
          placeholder="Enter end date"
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={formData.end_date}
        />
        <InputField
          id="reason"
          label="Reason"
          type="text"
          placeholder="Enter reason"
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={formData.reason}
        />
        <div className="flex justify-end gap-2">
          <Button type="reset" varient="secondary" className="w-max">Cancel</Button>
          <Button type="submit" varient="primary" className="w-max">Submit</Button>
        </div>
      </form>
    </Card>
  );
};

export default RequestLeaveForm;
