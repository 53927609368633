const config = {
  google: {
    CLIENT_ID: '833104405828-nn14nc9psmlscdarh2sro0un7cqit83n.apps.googleusercontent.com',
    // REDIRECT_URL: "http://localhost:3000/auth/sign-in",
    REDIRECT_URL: "https://erp.telerelation.com/auth/sign-in",
  },
  app: {
    // BACKEND_URL : "http://localhost:8000",
    BACKEND_URL : "https://api.erp.telerelation.com",

  },
};

export default config;
