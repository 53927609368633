import { useState } from "react";
import Card from "components/Card";
import InputField from "components/InputField";
import FileUploadField from "components/FileInputField";
import SelectField from "components/SelectField";
import Button from "components/Button";
import { createEmployeeAPI } from "api/apiHelper";
import { toast } from "react-toastify";
import { useAppDispatch } from "app/hooks";
import { useNavigate } from 'react-router-dom';
import ErrorBox from "components/ErrorBox";
import { setEmployeeProfileDetails } from "app/slices/Employee/thunk";
import { EmployeeCreateType, DependantsDataType } from "types/employee-types";

const BioDataForm = () => {
  const [error, setError] = useState<string>("");
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<EmployeeCreateType>({
    employee: {
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
      CNIC: "",
      phone: "",
      address: "",
      photo: null,
    },
    dependents: [],
  });

  const navigate = useNavigate();

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const notification = toast.loading("Creating Employee...");
    if (!formData.employee.photo) {
      toast.update(notification, {
        render: "Photo is required field.",
         type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      return;
    }
    console.log("Create Emp: ",formData);
    try {
      
      const response = await createEmployeeAPI(formData);
      console.log(response);
      toast.update(notification, {
        render: "Employee created successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      dispatch(setEmployeeProfileDetails());
      navigate('/');
    } catch (error:any) {
      toast.update(notification, {
        render: "Failed to create Employee",
         type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      console.log(error);
      setError(error.message);
    }
  };

  const handleFormReset = () => {
    setFormData({
      employee: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        gender: "",
        CNIC: "",
        phone: "",
        address: "",
        photo: null,
      },
      dependents: [],
    });
  };

  const handleFileChange = (file: File | null) => {
    if (file) {
      setFormData({
        ...formData,
        employee: {
          ...formData.employee,
          photo: file,
        },
      });
    }
  };

  const handleFileCancel = () => {
    setFormData({
      ...formData,
      employee: {
        ...formData.employee,
        photo: null,
      },
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      employee: {
        ...formData.employee,
        [id]: value,
      },
    });
  };

  const  handleDependentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { id, value } = e.target;
    const newDependents = formData.dependents.map((dependent, i) => {
      if (i === index) {
        return {
          ...dependent,
          [id]: value,
        };
      }
      return dependent;
    });

    setFormData({
      ...formData,
      dependents: newDependents,
    });
  };

  const handleAddDependent = () => {
    const newDependent: DependantsDataType = {
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "M",
      relationship: "Spouse",
    };

    setFormData({
      ...formData,
      dependents: [...formData.dependents, newDependent],
    });
  };

  return (
    <Card extra={"w-full h-full pb-10 p-4"}>
      {/* Header */}
      <div className="flex items-center px-4 py-2 mb-3 bg-orange-100 text-orange-700 rounded-md">
        <svg
          className="h-6 w-6 mr-2 text-orange-500"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M12 16v-4M12 8h.01" />
        </svg>
        <span>Complete Bio Data for Full Telerelation ERP Access.</span>
      </div>
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Bio Data Form
        </h4>
      </div>

      {/* Form */}
      { error && <ErrorBox errorMessage={error}/> }
      <form
        className="flex flex-col gap-4"
        onSubmit={handleFormSubmit}
        onReset={handleFormReset}
      >
        <FileUploadField
          id="photo"
          label="Profile photo"
          acceptedFileTypes={[
            "image/jpeg",
            "image/png",
            "image/jpg",
            "image/svg",
          ]}
          onFileChange={handleFileChange}
          onCancel={handleFileCancel}
          required={!formData.employee.photo}
        />
        <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-2">
          <InputField
            id="first_name"
            label="First Name"
            type="text"
            placeholder="Enter first name"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.first_name}
            required
          />
          <InputField
            id="last_name"
            label="Last Name"
            type="text"
            placeholder="Enter last name"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.last_name}
            required
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
          <InputField
            id="date_of_birth"
            label="Date of Birth"
            type="date"
            placeholder="Enter date of birth"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.date_of_birth}
            required
          />
          <SelectField
            id="gender"
            label="Choose a gender"
            options={[
              { label: "Select", value: ""},
              { label: "Male", value: "M" },
              { label: "Female", value: "F" },
              { label: "Other", value: "O" },
            ]}
            onChange={handleChange}
            required
          />
          <InputField
            id="CNIC"
            label="CNIC"
            type="number"
            placeholder="Enter CNIC"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.CNIC}
            required
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 pb-6 lg:grid-cols-2">
          <InputField
            id="phone"
            label="Phone"
            type="text"
            placeholder="Enter phone"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.phone}
            required
          />
          <InputField
            id="address"
            label="Address"
            type="text"
            placeholder="Enter address"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.address}
            required
          />
        </div>

        <div className="mb-8 mt-2 flex w-full items-center justify-between border-t pt-8">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Dependants Details
          </h4>
          <Button
            type="submit"
            varient="primary"
            className="rounded px-4 py-2"
            onClick={handleAddDependent}
          >
            Add Dependent
          </Button>
        </div>

        {formData.dependents.map((dependent, index) => (
          <div className="mb-8 mt-2 w-full flex flex-col gap-4" key={index}>
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              Dependant {index + 1}
            </h4>
            <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-2">
              <InputField
                id="first_name"
                label="First Name"
                type="text"
                placeholder="Enter first name"
                extra="w-full"
                variant="outlined"
                onChange={(e) => handleDependentChange(e, index)}
                value={formData.dependents[index].first_name}
                required
              />
              <InputField
                id="last_name"
                label="Last Name"
                type="text"
                placeholder="Enter last name"
                extra="w-full"
                variant="outlined"
                onChange={(e) => handleDependentChange(e, index)}
                value={formData.dependents[index].last_name}
                required
              />
            </div>
            <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
              <InputField
                id="date_of_birth"
                label="Date of Birth"
                type="date"
                placeholder="Enter date of birth"
                extra="w-full"
                variant="outlined"
                onChange={(e) => handleDependentChange(e, index)}
                value={formData.dependents[index].date_of_birth}
                required
              />
              <SelectField
                id="gender"
                label="Choose a gender"
                options={[
                  { label: "Select", value: ""},
                  { label: "Male", value: "M" },
                  { label: "Female", value: "F" },
                  { label: "Other", value: "O" },
                ]}
                onChange={(e) => handleDependentChange(e, index)}
                required
              />
              <SelectField
                id="relationship"
                label="Relationship"
                options={[
                  { label: "Select", value: ""},
                  { label: "Spouse", value: "S" },
                  { label: "Child", value: "C" },
                  { label: "Parent", value: "P" },
                  { label: "Other", value: "O" },
                ]}
                onChange={(e) => handleDependentChange(e, index)}
                required
              />
            </div>
          </div>
        ))}
        <div className="flex justify-end gap-2">
          <Button type="reset" varient="secondary" className="w-max">
            Cancel
          </Button>
          <Button type="submit" varient="primary" className="w-max">
            Submit
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default BioDataForm;
