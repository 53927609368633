// Loading.js

import React from 'react';

const Backdrop = ({
  show
}: any) => {

    if (!show) {
        return null; // Render nothing if show is false
    }
    
      return (
        <div className="fixed inset-0 flex items-center justify-center h-screen bg-gray-800 bg-opacity-50">
          <div className="animate-spin rounded-full border-t-4 border-gray-300 border-solid h-16 w-16"></div>
        </div>
      );
};

export default Backdrop;
