import { useState, useEffect } from "react";
import  { useNavigate, useParams } from "react-router-dom";
import { getFullAppraisalForm } from "api/apiHelper";
import AppraisalFormComponent from "features/appraisals/AppraisalFormComponent";

export interface QuestionOptionType {
  id: number;
  option_text: string;
  rating: number;
  question: number;
}

export interface QuestionType {
  id: number;
  question_text: string;
  question_type: string;
  weightage: number;
  options: QuestionOptionType[];
  form: number;
}

export interface FullFormType {
  id: number; 
  title: string;
  description: string; 
  created_by: number;
  created_at: string;
  updated_at: string;
  questions: QuestionType[];
}

const AppraisalForm = () => {
  const { appraisalId } = useParams<{ appraisalId: string }>();
  const [form, setForm] = useState<FullFormType | null>(null);
  const navigate = useNavigate();
  const fetchForm = async (id: string) => {
    try {
      const response = await getFullAppraisalForm(id);
      setForm(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if(!appraisalId.match(/\d+/)) {
      navigate('/not-found');
      return;
    }

    fetchForm(appraisalId);
  }, [appraisalId, navigate]);

  useEffect(() => {
    console.log(form, "form");
  }, [form]);

  return (
    <div className="mt-5">
      <AppraisalFormComponent formData={form} />
    </div>
  );
};

export default AppraisalForm;
