import React from 'react';

export interface TabPropsType {
  isActive: boolean;
  onClick?: () => void;
  title: string;
  children?: React.ReactNode;
}

const Tab: React.FC<TabPropsType> = ({ isActive, title, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-full focus:outline-none
      ${isActive ? 'bg-blue-100 text-blue-700 dark:bg-navy-600 dark:text-white' : 'bg-white text-black dark:bg-navy-800 dark:text-white'}
    `}
  >
    {title}
  </button>
);

export default Tab;
