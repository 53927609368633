import React from "react";

// Admin Imports
import EmployeesLeaves from "pages/leaves/Leaves";
import EmployeeLeavesDetails from "pages/leaves/EmployeeLeavesDetails";
import LeaveDetail from "pages/leaves/LeaveDetail";
import CreateContract from "features/contracts/CreateContract";
import CreatePayslip from "pages/payslips/CreatePayslip";
import CreatePayslipBatch from "pages/payslips/CreatePayslipBatch";
import Payslip from "pages/payslips/Payslip";
import Payslips from "pages/payslips/Payslips";
import PayslipBatch from "pages/payslips/PayslipBatch";
import PayslipBatches from "pages/payslips/PayslipBatches";

// Employee Imports
import Appraisals from "pages/appraisals/Appraisals";
import AppraisalForm from "pages/appraisals/AppraisalsForm";
import BioData from "pages/employees/BioData";
import EmployeePayslips from "pages/payslips/EmployeePayslips";
import EmployeeBioData from "pages/employees/EmployeeBioData";
import EditEmployeeBioData from "pages/employees/EditEmployeeBioData";

// Auth Imports
import SignIn from "pages/auth/SignIn";

// Icon Imports
import {
  MdBarChart,
  MdLock,
  MdFeedback,
  MdDescription,
  MdPayment,
  MdDateRange,
  MdOutlinePerson
} from "react-icons/md";

import PaySlipLineItemTypes from "pages/payslips/PayslipLineitemTypes";
import MyPayslips from "pages/payslips/MyPayslips";
import EmployeesList from "pages/employees/EmployeesList";
import EmployeesDetails from "pages/employees/EmployeeDetails";
import EmployeesProfile from "pages/employees/Profile";
import ShowContract from "pages/contracts/ShowContract";
import Home from "pages/auth/Home";

import MyContract from "pages/contracts/MyContract";

export const userRoutes: RoutesType[] = [
  {
    name: "Home",
    layout: "Main",
    icon: <MdOutlinePerson className="h-6 w-6" />,
    path: "/",
    display: false,
    permissions: ['employees.view_my_employee'],
    component: <Home />,
  },
  {
    name: "Profile",
    layout: "Main",
    icon: <MdOutlinePerson className="h-6 w-6" />,
    path: "/profile",
    display: true,
    permissions: ['employees.view_my_employee'],
    component: <EmployeesProfile />,
  },
  {
    name: "Employee List",
    layout: "Main",
    icon: <MdOutlinePerson className="h-6 w-6" />,
    path: "/all-employees",
    display: true,
    permissions: ['employees.view_employee'],
    component: <EmployeesList />,
  },
  {
    name: "Employee",
    layout: "Main",
    path: "/employee/:employeeId",
    display: false,
    permissions: ['employees.view_employee'],
    component: <EmployeesDetails />,
  },
  {
    name: "Edit Employee",
    layout: "Main",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/employee/:employeeId/edit-bio-data",
    display: false,
    permissions:['employees.change_employee'],
    component: <EmployeeBioData />,
  },
  {
    name: "Edit Profile",
    layout: "Main",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/edit-profile",
    display: false,
    permissions:['employees.change_my_employee'],
    component: <EditEmployeeBioData />,
  },
  {
    name: "Create Contract",
    layout: "Main",
    path: "/create-contract",
    display: true,
    icon: <MdDescription className="h-6 w-6" />,
    permissions:["contracts.add_contract"],
    component: <CreateContract />,
  },
  {
    name: "Contract",
    layout: "Main",
    path: "/employee/contract/:contractId",
    display: false,
    permissions:["contracts.view_my_contract"],
    component: <ShowContract />,
  },
  {
    name: "My Contract",
    layout: "Main",
    path: "/my-contract",
    display: true,
    permissions:["contracts.view_my_contract"],
    component: <MyContract />,
  },
  {
    name: "Create Payslip",
    layout: "Main",
    path: "/payslip/create",
    permissions: ['payroll.add_payslip'],
    display: false,
    component: <CreatePayslip />,
  },
  {
    name: "Payslip",
    layout: "Main",
    path: "/payslip/:payslipId",
    permissions: ['payroll.view_payslip'],
    display: false,
    component: <Payslip />,
  },
  {
    name: "My Payslip",
    layout: "Main",
    path: "/my-payslip/:payslipId",
    permissions: ['payroll.view_my_payslip'],
    display: false,
    component: <Payslip />,
  },
  {
    name: "Payslips",
    layout: "Main",
    path: "/payslip",
    permissions: ['payroll.view_payslip'],
    display: true,
    icon: <MdPayment className="h-6 w-6" />,
    component: <Payslips />,
  },
  {
    name: "My Payslips",
    layout: "Main",
    path: "/my-payslip",
    permissions: ['payroll.view_my_payslip'],
    display: true,
    icon: <MdPayment className="h-6 w-6" />,
    component: <MyPayslips />,
  },
  {
    name: "Create Payslip Batch",
    layout: "Main",
    path: "/payslip/batch/create",
    permissions: ['payroll.add_payslipbatch'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <CreatePayslipBatch />,
  },
  {
    name: "Leaves Manage",
    layout: "Main",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/employee/leaves",
    display: true,
    permissions:['leaves.view_employeeleavesdetails'],
    component: <EmployeesLeaves />,
  },
  {
    name: "My Leaves Details",
    layout: "Main",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/my-leaves/details/",
    display: true,
    permissions:['leaves.view_employeeleavesdetails', 'leaves.view_my_employeeleaveinfo', 'leaves.add_my_employeeleavesdetail', 'leaves.view_my_employeeleavesdetail', 'leaves.view_employeeleavetype'],
    component: <EmployeeLeavesDetails />,
  },
  {
    name: "Leave Details",
    layout: "Main",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "/leaves/:leaveId",
    display: false,
    permissions:['leaves.view_employeeleavesdetails'],
    component: <LeaveDetail />,
  },
  {
    name: "Bio Data",
    layout: "Main",
    icon: <MdOutlinePerson className="h-6 w-6" />,
    path: "/bio-data",
    display: false,
    permissions:['employees.add_my_employee'],
    component: <BioData />,
  },
  {
    name: "Payslip Line Item Types",
    layout: "Main",
    path: "/payslip/line-item-types",
    permissions: ['payroll.view_paysliplineitemtype', 'payroll.add_paysliplineitemtype'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <PaySlipLineItemTypes />,
  },
  {
    name: "Payslip Batches",
    layout: "Main",
    path: "/payslip/batch",
    permissions: ['payroll.view_payslipbatch'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <PayslipBatches />,
  },
  {
    name: "Payslip Batch",
    layout: "Main",
    path: "/payslip/batch/:batchId",
    permissions: ['payroll.view_payslipbatch'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <PayslipBatch />,
  },
  {
    name: "Batch Payslip",
    layout: "Main",
    path: "/payslip/batch/:batchId/:payslipId",
    permissions: ['payroll.view_payslip'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <Payslip />,
  },
  {
    name: "Payslips Employee",
    layout: "Main",
    path: "/payslip/emp/:employeeId",
    permissions: ['payroll.view_payslip'],
    display: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <EmployeePayslips />,
  },
  {
    name: "Appraisals",
    layout: "Main",
    path: "/employee/appraisal",
    display: true,
    permissions:['appraisals.view_appraisals'],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <Appraisals />,
  },
  {
    name: "Appraisal Form",
    layout: "Main",
    path: "/employee/appraisal/form/:appraisalId",
    display: false,
    permissions:['appraisals.add_appraisals'],
    icon: <MdFeedback className="h-6 w-6" />,
    component: <AppraisalForm />,
  },
];

export const authRoutes: RoutesType[] = [
  {
    name: "Sign In",
    layout: "Minimal",
    path: "/auth/sign-in",
    display: false, 
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
]

export const allRoutes: RoutesType[] = [...authRoutes,...userRoutes] ;