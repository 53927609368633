interface Clause {
  id: number;
  clause_text: string;
}

interface ContractClausePickerProps {
  selectClauses: Clause[];
  selectedClauses: Clause[];
  onClauseClick: (clauseId: number, isFromAvailable: boolean) => void;
}

const ContractClausePicker: React.FC<ContractClausePickerProps> = ({ selectClauses, selectedClauses, onClauseClick }) => {

  return (
    <div className="flex flex-row gap-8 text-gray-900 dark:text-white">
      <div className="w-1/2 border border-gray-300 p-4 rounded-lg">
        <h3 className="text-base font-semibold leading-7 mb-2">Available Clauses</h3>
        <ul>
          {selectClauses.map((clause) => (
            <li key={clause.id} className="flex items-center justify-between mb-2">
              <span className="ps-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">{clause.clause_text}</span>
              <button
                className="px-2 ms-1 py-1 bg-gray-200 dark:bg-navy-600 rounded-md hover:bg-gray-300"
                onClick={() => onClauseClick(clause.id, true)}
              >
                {"=>"}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="w-1/2 border border-gray-300 p-4 rounded-lg">
        <h3 className="text-base font-semibold leading-7 mb-2">Selected Clauses</h3>
        <ul>
          {selectedClauses.map((clause) => (
            <li key={clause.id} className="flex items-center justify-between mb-2">
              <span className="ps-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">{clause.clause_text}</span>
              <button
                className="px-2 ms-1 py-1 bg-gray-200 dark:bg-navy-600 rounded-md hover:bg-gray-300"
                onClick={() => onClauseClick(clause.id, false)}
              >
                {"<="}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ContractClausePicker;
