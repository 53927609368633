import { useState, useEffect } from "react";
import Card from "components/Card";
import InputField from "components/InputField";
import SelectField from "components/SelectField";
import Button from "components/Button";
import { getAllEmployees, getLeaveTypes, postLeaveInfo } from "api/apiHelper";
import { LeaveInfoType } from "features/leaves/RegisterLeaveTypeForm";
import { toast } from "react-toastify";

export interface AssignMaxLeavesType {
  employee: string;
  max_leaves_allowed: string;
  leave_type: string;
  period_start: string;
  period_end: string;
}

interface optionsType {
  value: string;
  label: string;
}

const AssignMaxLeavesForm = () => {
  const [employeesData, setEmployeesData] = useState<EmployeeType[]>([]);
  const [leaveTypesData, setLeaveTypesData] = useState<LeaveInfoType[]>([]);
  const [employeeOptions, setEmployeeOptions] = useState<optionsType[]>([]);
  const [leaveTypeOptions, setLeaveTypeOptions] = useState<optionsType[]>([]);
  const [assignMaxLeavesData, setAssignMaxLeavesData] = useState<AssignMaxLeavesType>({
    employee: "",
    max_leaves_allowed: "",
    leave_type: "",
    period_start: "",
    period_end: "",
  });
  
  const fetchEmployees = async () => {
    try {
      const response = await getAllEmployees();
      setEmployeesData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLeaveTypes = async () => {
    try {
      const response = await getLeaveTypes();
      setLeaveTypesData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchLeaveTypes();
  }, []);

  useEffect(() => {
    const options = employeesData.map((employee) => ({
      value: employee.id,
      label: `${employee.first_name} ${employee.last_name}`,
    }));
    options.unshift({ value: "", label: "Select an employee" });
    setEmployeeOptions(options);
  }, [employeesData]);

  useEffect(() => {
    const options = leaveTypesData.map((leave) => ({
      value: leave.id.toString(),
      label: leave.description,
    }));
    options.unshift({ value: "", label: "Select a leave type" });
    setLeaveTypeOptions(options);
  }, [leaveTypesData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setAssignMaxLeavesData({
      ...assignMaxLeavesData,
      [id]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    let notification = toast.loading("Assigning Max Leaves...");

    try {
      const response = await postLeaveInfo(assignMaxLeavesData);
      console.log(response);
      toast.update(notification, {
        render: "Max Leaves assigned successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      handleReset();
    } catch (error) {
      console.log(error);
      toast.update(notification, {
        render: "Failed to assign Max Leaves",
         type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const handleReset = () => {
    setAssignMaxLeavesData({
      employee: "",
      max_leaves_allowed: "",
      leave_type: "",
      period_start: "",
      period_end: "",
    });
  };

  return (
    <Card extra={"w-full h-full pb-10 p-4"}>
      {/* Header */}
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Assign Max Leaves
        </h4>
      </div>

      {/* Form */}
      <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit} onReset={handleReset}>
        <SelectField 
          id="employee"
          label="Choose an Employee"
          options={employeeOptions}
          onChange={handleChange}
          value={assignMaxLeavesData.employee}
        />
        <SelectField 
          id="leave_type"
          label="Choose a Leave Type"
          options={leaveTypeOptions}
          onChange={handleChange}
          value={assignMaxLeavesData.leave_type}
        />
        <InputField
          id="max_leaves_allowed"
          label="Max Leaves Allowed"
          type="number"
          placeholder="Enter max leaves allowed"
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={assignMaxLeavesData.max_leaves_allowed}
        />
        <InputField
          id="period_start"
          label="Period Start"
          type="date"
          placeholder="Enter start date"
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={assignMaxLeavesData.period_start}
        />
        <InputField
          id="period_end"
          label="Period End"
          type="date"
          placeholder="Enter end date"
          extra="w-full"
          variant="outlined"
          onChange={handleChange}
          value={assignMaxLeavesData.period_end}
        />
        <div className="flex justify-end gap-2">
          <Button type="reset" varient="secondary" className="w-max">Cancel</Button>
          <Button type="submit" varient="primary" className="w-max">Submit</Button>
        </div>
      </form>
    </Card>
  );
};

export default AssignMaxLeavesForm;
